import React, { useEffect } from 'react'
import { isBrowser } from '../services/auth'
import { navigate } from 'gatsby'
import Layout from '../components/Layout'
import { ActivityIndicator, Button } from '../components/generic'
import gql from 'graphql-tag'
import { useQuery } from 'react-apollo'
import { MY_ADDRESS } from '../config'

const GET_AUTHORIZATION_URL = gql`
  query ($clientId: String!, $redirectUrl: String!) {
    authorizationUrl: getAuthorizationUrl(clientId: $clientId, redirectUrl: $redirectUrl)
  }
`

const Wrapper: React.FC = ({ children }) => (
  <Layout noHeader noFooter noMoku>
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '16px',
        padding: '16px',
      }}
    >
      {children}
    </div>
  </Layout>
)

const Redirect: React.FC<{ clientId: string }> = ({ clientId }) => {
  const { data } = useQuery(GET_AUTHORIZATION_URL, {
    variables: { redirectUrl: `${MY_ADDRESS}/link-accounts`, clientId },
  })

  useEffect(() => {
    if (data && data.authorizationUrl) {
      window.location = data.authorizationUrl
    }
  }, [data])

  return (
    <Wrapper>
      <p>We are redirecting you to our partner to securely link your accounts</p>
      <div>
        <ActivityIndicator />
      </div>
    </Wrapper>
  )
}

const Callback: React.FC<{ success: boolean }> = ({ success }) => {
  if (success) {
    return (
      <Wrapper>
        <p>Your accounts have successfully been linked</p>
        <Button onClick={() => navigate('/sign-in')}>Continue to sign in</Button>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <p>An error occurred while linking your accounts</p>
      <Button onClick={() => navigate('/sign-in')}>Continue to sign in</Button>
    </Wrapper>
  )
}

export default function LinkAccounts() {
  if (!isBrowser()) {
    return <div />
  }

  const clientId = new URLSearchParams(window.location.search).get('clientId')
  const outcome = new URLSearchParams(window.location.search).get('authorizationStatus')

  if (outcome) {
    return <Callback success={outcome === 'success'} />
  } else if (clientId) {
    return <Redirect clientId={clientId} />
  }

  navigate('')

  return null
}
